/* https://material-ui.com/customization/color/ */

.mdFindings li a {
    color: #FFF16A;
    /*indigo.800 */
}

.mdFindings img {
    display: block;
    max-width: 100%;
}

.listFindings a {
    color: #3f51b5;
    /*indigo.800 */
}

pre {
    background: rgba(209, 209, 222, 0.3);
    border: none;
    /*color: rgb(0 0 0);*/
    display: inline-block;
    font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
    /*font-size: 12px;*/
    overflow-x: auto;
    padding: 10px;
    white-space: pre-wrap;
    /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
}

.customTable {
    width: 100%;
    overflow-x: auto;
    display: block;
}

.customTable table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid var(--tableBorder);
    table-layout: fixed;
    /* Forces equal column widths */
    min-width: 400px;
    /* Prevents table from becoming too squished */
}

.customTable th {
    text-transform: capitalize;
    background-color: var(--headerColor);
    color: white;
}

.customTable th,
.customTable td {
    text-align: left;
    padding: 8px;
    border: 1px solid var(--tableBorder);
    word-wrap: break-word;
    word-break: break-word;
    max-width: 300px;
    /* Limit cell width */
    white-space: normal;
}

.customTable tr td a {
    word-break: break-all;
}

.customTable li {
    margin-top: 2px;
    padding: 2px;
}

/* a{
    color: #E6F600 !important;
} */
.customTable a {
    color: #FFF16A;
}

.theme-light .mdFindings li a,
.theme-light .customTable a {
    color: #3f51b5;
    /*indigo.800 */
}